<template>
  <div>
    <div v-show="!readonly && valueStr === ''">
      <v-file-input
        v-model="value"
        :label="`${$t(label)}${(required) ? ' *' : ''}`"
        :color="color"
        :rules="rules"
        :prepend-icon="icon"
        ref="file"
      >
        <v-tooltip slot="append" bottom v-if="$t(tooltip)">
          <template v-slot:activator="{ on }">
            <v-icon slot="activator" v-on="on">mdi-help-circle</v-icon>
          </template>
          <span>{{ $t(tooltip) }}</span>
        </v-tooltip>
      </v-file-input>
    </div>
    <div v-show="readonly || valueStr !== ''">
      <v-text-field
        :label="`${$t(label)}${(required) ? ' *' : ''}`"
        :prepend-icon="icon"
        v-model="valueStr"
        :color="color"
        :rules="rules"
        v-on:click="file"
        readonly
      >
        <v-tooltip slot="append" bottom v-if="$t(tooltip)">
          <template v-slot:activator="{ on }">
            <v-icon slot="activator" v-on="on">mdi-help-circle</v-icon>
          </template>
          <span>{{ $t(tooltip) }}</span>
        </v-tooltip>
      </v-text-field>
    </div>
  </div>
</template>

<script>
import _ from '../../../misc/lodash';

export default {
  name: 'fileField',
  props: {
    componentData: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const rules = [];

    if (this.componentData.required) {
      rules.push((value) => (
        (typeof value === 'string' && /.+/.test(value))
        || (typeof value !== 'string' && !value)
        || this.$t('misc.required')));
    }

    return {
      label: this.componentData.label || 'fields.file',
      color: this.componentData.color || 'accent',
      value: null,
      valueStr: this.componentData.value || this.componentData.default || null,
      tooltip: (typeof this.componentData.description === 'undefined')
        ? 'fields.fileTooltip' : this.componentData.description,
      icon: (typeof this.componentData.icon === 'undefined')
        ? 'mdi-file' : this.componentData.icon,
      rules,
      required: this.componentData.required || false,
      fieldReadonly: this.readonly || !!this.componentData.readonly,
    };
  },
  watch: {
    componentData() {
      this.value = null;
      this.valueStr = this.componentData.value || this.componentData.default || null;

      if (!_.isEqual(this.fieldReadonly, this.componentData.readonly)
        || !_.isEqual(this.fieldReadonly, this.readonly)) {
        this.fieldReadonly = this.readonly || !!this.componentData.readonly;
      }
    },
    value() {
      this.valueStr = this.value.name;
      this.$emit('change', this.value);
    },
  },
  methods: {
    file() {
      if (!this.readonly) {
        this.$refs.file.$refs.input.click();
      }
    },
  },
};
</script>
